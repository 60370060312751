<template>
  <List
    :title="'Document'"
    :entries="bodyItems"
    :headItems="headItems"
    :hasCheck="true"
    :canCreate="canCreate"
    :loaded="loaded"
    :toFilter="toFilter"
    :tabFilters="tabFilters"
    :isActive="isActive"
    :total="total"
    :perPage="perPage"
    :currentPage="currentPage"
    @optionSel="handleOption"
    @create="create = true"
    @filters="filterByItem"
    @actions="handleAction"
    @search="handleSearch"
    @selectFilter="handleTabFilter"
  />
  <Pagination
    :entries="entries"
    :currentPage="currentPage"
    :total="total"
    :perPage="perPage"
    :pages="pages"
    @goToFirst="currentPage = 1"
    @goToLast="currentPage = pages"
    @goToPage="handlePage"
    @changePage="handlePageChange"
  />
  <preview
    v-if="folder"
    :type="'folder'"
    :info="selFolder"
    @close="folder = false"
  />
  <edit-modal
    v-if="editItem"
    :title="`Edit Document`"
    :items="toEdit"
    :response="response"
    @close=";(editItem = false), (response = [])"
    @submitData="editDoc"
  />
  <create-modal
    v-if="create"
    :items="toCreate"
    :title="`Add Document`"
    @submitData="createNew"
    @close="create = false"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { GET_USER_PROFILE_GETTER } from "@/store/storeconstants";
import axiosInstance from "@/services/AxiosTokenInstance";
import List from "@/components/List/Index.vue";
import Pagination from "@/components/List/Pagination.vue";
import timeSince from "@/mixins/timeSince";
import isMobile from "@/mixins/isMobile";
import Preview from "@/components/previews/Preview.vue";
import EditModal from "@/components/Ui/CRUD/Edit.vue";
import CreateModal from "@/components/Ui/CRUD/Create.vue";
import api from "@/mixins/optionsApi";

export default {
  title() {
    return `Remote Assistance | ${this.title}`;
  },
  data() {
    return {
      title: "Documents",
      entries: [],
      currentPage: 1,
      filteredEntries: [],
      total: 0,
      pages: 0,
      perPage: 10,
      imgURL: process.env.VUE_APP_DO_SPACES,
      loaded: false,
      filter: [],
      tabFilters: [
        {
          name: "Category Documents",
          filter: "category",
          isActive: true,
        },
        {
          name: "Product Documents",
          filter: "product",
          isActive: false,
        },
      ],
      isActive: 0,
      canCreate: [4, 5, 6, 7, 8],
      headItems: ["File", "Category", "Last Edit"],
      bodyItems: [],
      editOptions: ["see", "edit"],
      search_key: "",
      hasOptions: true,
      see: false,
      editItem: false,
      create: false,
      selItem: 0,
      client: localStorage.getItem("client")
        ? JSON.parse(localStorage.getItem("client"))
        : null,
      roles: [],
      type: "",
      folder: false,
      selFolder: {},
      response: []
    };
  },

  provide() {
    return {
      edit: this.editOptions,
      hasOptions: this.hasOptions,
    };
  },

  components: {
    List,
    Pagination,
    Preview,
    EditModal,
    CreateModal,
  },

  mixins: [timeSince, isMobile, api],

  watch: {
    currentPage: {
      immediate: true,
      handler(val) {
        this.getData(val, this.type);
      },
    },

    filter() {
        if (this.currentPage !== 1) {
            this.currentPage = 1
            return
        }
        this.getData(1, this.type);
    },

    entries() {
      this.fillBodyItems();
    },

    search_key() {
        if (this.currentPage !== 1) {
            this.currentPage = 1
            return
        }
        this.getData(1, this.type);
    },

    type(val) {
      this.isActive = this.tabFilters.findIndex((el) => el.filter === val);
      if (val === "product") {
        this.headItems = ["File", "Product", "Last Edit"];
        this.fillBodyItems();
      } else {
        this.headItems = ["File", "Category", "Last Edit"];
        this.fillBodyItems();
      }
      this.getData(1, val);
    },
  },

  computed: {
    ...mapGetters("account", {
      getUserProfile: GET_USER_PROFILE_GETTER,
    }),
    currentTotal() {
      return this.total > 10 && this.perPage * this.currentPage < this.total
        ? this.perPage * this.currentPage
        : this.total;
    },
    toFilter() {
      if (this.type === "category" || this.type === "") {
        return [
          {
            name: "categories",
            options: this.api("categories"),
          },
        ];
      }
      return [
        {
          name: "products",
          options: this.api("products"),
        },
      ];
    },
    toCreate() {
      return [
        {
          type: "text",
          label: "Name",
        },
        {
          type: "choice",
          label: "Belongs to",
          options: [
            {
              label: "category",
              options: this.api("categories"),
            },
            {
              label: "product",
              options: this.api("products"),
            },
          ],
        },
        {
          type: "file",
          label: "Files",
        },
      ];
    },
    toEdit() {
      const item = this.entries.find((el) => el.id === this.selItem) || {};
      if (Object.keys(item).length) {
        return [
          {
            type: "text",
            label: "Name",
            value: item.name,
          },
          {
            type: "choice",
            label: "Belongs to",
            value: [
              item.product_id ? "product" : "category",
              item.product || item.category || {},
            ],
            options: [
              {
                label: "category",
                options: this.api("categories"),
              },
              {
                label: "product",
                options: this.api("products"),
              },
            ],
          },
          {
            type: "select",
            label: "Status",
            options: [
              { id: 0, name: "Inactive" },
              { id: 1, name: "Active" },
            ],
            value: {
              id: item.status === 1 ? 1 : 2,
              name: item.status === 1 ? "Active" : "Inactive",
            },
          },
        ];
      }
      return [];
    },
  },
  methods: {
    handlePageChange(to) {
      switch (to) {
        case "less":
          this.currentPage -= 1;
          break;
        case "more":
          this.currentPage += 1;
          break;
      }
    },

    handlePage(to) {
        this.currentPage = to
    },

    fillBodyItems() {
      this.bodyItems = this.entries.map((item) => {
        let toReturn = [
          {
            template: "file",
            id: item.id,
            data: {
              image:
                item.files.length <= 1
                  ? "/assets/images/doc-icon.svg"
                  : "/assets/images/folder_icon.svg",
              title: item.name,
              extension:
                item.files.length && item.files.length <= 1
                  ? `.${item.files[0].file_name.split('.')[item.files[0].file_name.split('.').length - 1]}`
                  : !item.files.length
                  ? "null"
                  : "folder",
              description: [
                {
                  light: true,
                  text:
                  item.files.length && item.files.length <= 1
                      ? `file size: ${item.files[0].file_size}`
                      : `Files inside: ${item.files.length}`,
                },
              ],
            },
          },
          {
            template: "normal",
            data: item.product?.name || item.category?.name || "undefined",
          },
          {
            template: "image",
            data: {
              image: item.user ? this.imgURL + item.user.avatar : null,
              title: item.user ? item.user.name : "undefined",
              description: [
                {
                  light: false,
                  text: this.timeSince(item.updated_at),
                },
              ],
            },
          },
        ];

        return toReturn;
      });
    },

    async getData(value, filter) {
      this.loaded = false;
      let orgDataString = localStorage.getItem("organization");
      this.entries = [];
      this.perPage = 0;
      this.total = 0;
      this.pages = 0;
      const client_id = this.client ? this.client.id : "";
      if (orgDataString) {
        let orgData = JSON.parse(orgDataString);
        let response;

        if (!this.filter.length)
          response = await axiosInstance
            .get(
              `document?org_id=${orgData.id}&searchKey=${this.search_key}&clientId=${client_id}&type=${filter}&page=${value}`
            )
            .finally(() => (this.loaded = true));
        else
          response = await axiosInstance
            .get(
              `document?org_id=${orgData.id}&searchKey=${this.search_key}&${this.filter[0]}=${this.filter[1]}&clientId=${client_id}&type=${filter}&page=${value}`
            )
            .finally(() => (this.loaded = true));
        let responseData = response.data.data;
        this.entries = responseData.data;
        this.perPage = responseData.per_page;
        this.total = responseData.total;
        this.pages = responseData.last_page;
      }
    },

    filterByItem(data) {
      switch (data[0]) {
        case "categories":
          this.filter = ["categoryId", data[1]];
          break;
        case "products":
          this.filter = ["productId", data[1]];
          break;
        case "remove":
          this.filter = [];
          break;
      }
    },

    async createNew(data) {
      const org = JSON.parse(localStorage.getItem("organization"));

      let toSend = new FormData();
      let index = 0;
      data.Files.forEach((element) => {
        toSend.append(`doc_files[${index}][file]`, element.og_doc);
        toSend.append(`doc_files[${index}][file_type]`, element.extension);
        toSend.append(`doc_files[${index}][file_size]`, element.size);
        index += 1;
      });

      toSend.append("name", data.Name || data.Files[0].name);
      toSend.append("org_id", org.id);
      toSend.append("type", data["Belongs to"].type);
      toSend.append("id", data["Belongs to"].id);

      const response = await axiosInstance.post(`document`, toSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response) {
        this.emitter.emit("alert", response.data.message);
        this.getData(1, this.type);
      }
    },

    handleAlert(data) {
      this.emitter.emit("alert", data);
    },

    handleOption(data) {
      this.selItem = data[1];
      const item = this.entries.find((el) => el.id === this.selItem);
      switch (data[0]) {
        case "see":
          if (item.files.length > 1) {
            this.folder = true;
            this.selFolder = item;
          } else {
            if (item.file) window.open(this.imgURL + item.file);
            else window.open(this.imgURL + item.files[0].file);
          }
          break;
        case "edit":
          this.editItem = true;
          break;
        case "delete":
          this.deleteDoc();
          break;

        default:
          break;
      }
    },

    async editDoc(data) {
      const res = await axiosInstance.post(`document`, {
        documentId: this.selItem,
        org_id: JSON.parse(localStorage.getItem("organization")).id,
        status: data.Status.id,
        name: data.Name,
        type: data["Belongs to"].type || this.type || 'category',
        id: data["Belongs to"].id,
      });

      if (res.status === 200) {
        this.emitter.emit("alert", res.data.message);
        this.getData(1, this.type);
        this.response = [true, true];
      } else {
        this.response = [true, false];
      }

      this.selItem = 0;
    },

    async deleteDoc() {
      const res = await axiosInstance.delete(
        `document/${this.selItem}?type=${this.type}`
      );

      if (res.status === 200) {
        this.emitter.emit("alert", res.data.message);
        this.getData(1, this.type);
      }

      this.selItem = 0;
    },

    handleAction(data) {
      if (data.name === "delete selected") {
        data.data.forEach(async (element) => {
          await axiosInstance
            .delete(`document/${element}?type=${this.type}`)
            .finally(() => {
              this.getData(1, this.type);
            });
        });
      }
    },

    handleSearch(data) {
      this.search_key = data;
    },

    handleTabFilter(value) {
      this.type = value;
    },
  },
};
</script>